import React from 'react'
import { ContentProps } from './types'
import styles from './ContentHighlight.module.scss'
import { Container, Box, Flex, Column, Row } from '@components/UI/Grid/Grid'
import Markdown from '@components/UI/Markdown/Markdown'
import Label from '@components/UI/Label/Label'
import { getThemeColor, stringify } from '@lib/helpers'
import LinkComponent from '@components/UI/LinkComponent/LinkComponent'
import CtaButton from '@components/CtaButton/CtaButton'

const ContentHighlight: React.FC<ContentProps> = ({ blok, isSeoPage }) => {
   let { cta }: any = blok
   const hasLink = cta && cta.length > 0
   return (
      <Box
         as="section"
         width={1}
         className={[styles.wrapper, isSeoPage ? styles.seoPage : ''].join(' ')}
      >
         <Box className="content-inner" paddingY={[0, null, null, 0]}>
            <Container>
               <Row flexDirection="column" alignItems="center">
                  <Column
                     width={[1, null, null, 1]}
                     maxWidth={['924px', null, null, null, null, '1024px']}
                  >
                     <Flex
                        alignItems="center"
                        flexDirection={['column', null, 'row']}
                        justifyContent="space-between"
                     >
                        {hasLink && (
                           <CtaButton
                              blok={{
                                 _uid: '',
                                 component: 'cta_button',
                                 link: [cta[0]],
                                 color: 'purple',
                                 background_color: 'gray',
                                 hover_color: 'purple',
                              }}
                           />
                           // <CtaButton
                           //    href={cta[0].link && cta[0].link.cached_url}
                           //    className="button purple denese"
                           // >
                           //    {cta[0].title}
                           // </CtaButton>
                        )}
                     </Flex>
                  </Column>
               </Row>
            </Container>
         </Box>
      </Box>
   )
}

export default ContentHighlight
