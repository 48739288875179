import DynamicComponent from '@components/DynamicComponent/DynamicComponent'
import { Box, Flex } from '@components/UI/Grid/Grid'
import Label from '@components/UI/Label/Label'
import LinkComponent from '@components/UI/LinkComponent/LinkComponent'
import React, { useState } from 'react'
import styles from './PostGridCard.module.scss'
import classnames from 'classnames'
import Image from '@components/UI/Image/Image'
import useTranslation from 'next-translate/useTranslation'
import moment from 'moment'
import { useRouter } from 'next/router'

const getLabel = (type: string, nextTranslate: any) => {
   switch (type) {
      case 'page_article':
         return nextTranslate('article')
      case 'page_seo':
         return nextTranslate('resource')
      case 'page_podcast':
         return nextTranslate('podcast')
      case 'page_video':
         return nextTranslate('video')
      case 'page_publication':
         return nextTranslate('publication')
      case 'page_news':
         return nextTranslate('monthlyLetter')
      case 'page_portfolio_update':
         return nextTranslate('portfolioUpdate')
      default:
         return ''
   }
}

export const CardContent = ({
   item,
   preload,
   background,
   hideTag,
   hideDesc,
   noHover,
}: any) => {
   const { t } = useTranslation('common')
   const { locale } = useRouter()
   const { first_published_at }: any = item || {}
   const { title, excerpt, video, image, description, list_image }: any =
      item && item.content ? item.content : {}

   let { component }: any = item && item.content ? item.content : {}
   const [hideContent, setHideContent] = useState(false)
   if (item && item.full_slug && item.full_slug.includes('portfolio-updates')) {
      component = 'page_portfolio_update'
   }
   const label = getLabel(component, t)

   const cardImage =
      list_image && list_image.filenmae
         ? list_image
         : image && image.filename
         ? image
         : null

   return (
      <Flex flexDirection="column" width={1}>
         <Flex
            onClick={() => {
               if (component === 'page_video') {
                  setHideContent(true)
               }
            }}
            className={classnames(
               styles.wrapper,
               component === 'page_seo' ? styles.seo : '',
            )}
            width={1}
            height="100%"
            flexDirection="column"
            color={cardImage ? '#fff' : '#19180a'}
         >
            <Box className={styles.imageWrapper} marginBottom={[4]}>
               <Box
                  className={[styles.image, noHover ? styles.noHover : ''].join(
                     ' ',
                  )}
               >
                  {((cardImage && cardImage.filename) ||
                     (video &&
                        video.length > 0 &&
                        video[0].poster_image &&
                        video[0].poster_image.filename)) && (
                     <React.Fragment>
                        <Image
                           preload={preload}
                           isCritical={preload}
                           src={
                              cardImage?.filename ||
                              video[0].poster_image.filename
                           }
                           sizes={{
                              mobile: '50vw',
                              tablet: '50vw',
                              desktop: '30vw',
                           }}
                           alt={title}
                           offScreen
                        />
                        <div className={styles.filter}></div>
                     </React.Fragment>
                  )}
               </Box>
            </Box>

            {(label || first_published_at) && (
               <Flex
                  marginBottom={[2, null, null, hideDesc ? 4 : 6]}
                  className={styles.metadata}
                  alignItems="center"
               >
                  {label && !hideTag && (
                     <Box
                        as="span"
                        marginRight={2}
                        className={classnames(
                           styles.tag,
                           background === 'grey' ? styles.dark : '',
                        )}
                     >
                        {label}
                     </Box>
                  )}
                  {first_published_at && (
                     <span className={styles.date}>
                        {moment(first_published_at)
                           .locale(locale || 'en')
                           .format('MMMM DD, YYYY')}
                     </span>
                  )}
               </Flex>
            )}
            <Flex
               className={styles.content}
               alignItems="flex-start"
               flexDirection="column"
               height="100%"
               paddingRight={[0, null, null, 5]}
               paddingLeft={hideTag ? 0 : [1]}
            >
               <Box as="h5">{title}</Box>
               {(excerpt || description) && !hideDesc && (
                  <Box as="p" className={styles.excerpt} marginTop={[2]}>
                     {excerpt || description}
                  </Box>
               )}
            </Flex>
         </Flex>
      </Flex>
   )
}

const PostGridCard = ({ item, preload, background }: any) => {
   const { t } = useTranslation('common')

   const { full_slug } = item
   const { component }: any = item && item.content ? item.content : {}

   const label = getLabel(component, t)

   return (
      <LinkComponent className={styles.link} href={full_slug}>
         <CardContent preload={preload} item={item} background={background} />
      </LinkComponent>
   )
}

export default PostGridCard
