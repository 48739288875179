import React, { Fragment, useEffect, useState } from 'react'
import { getHoldings } from '@lib/getHoldings'
import { updateHoldingsDatabase } from '@lib/updateHoldingsDatabase'
import styles from './HoldingTable.module.scss'
import useTranslation from 'next-translate/useTranslation'

interface Props {
   tableName: string
}

const HoldingTable = ({ tableName }: Props) => {
   const [data, setData]: any = useState([])
   const [sort, setSort]: any = useState({ key: 'category', invert: false })
   const { t } = useTranslation('common')

   const isMarketOpen = (date: any) => {
      const day = date.getDay(),
         hour = date.getHours(),
         minutes = date.getMinutes()
      if (day === 0 || day === 6) {
         if (day === 0) {
            return 'sun'
         }
         return 'sat'
      }

      if (hour > 22) {
         if (minutes < 10) {
            return 'open'
         } else {
            return 'late'
         }
      }
      if (hour < 15) {
         return 'early'
      }
      return 'open'
   }

   const updateDatabase = async (
      holdings: any,
      tableName: string,
      delay: any,
   ) => {
      const response = await updateHoldingsDatabase(holdings, tableName, delay)
      setData(response)
   }

   const fetchHoldings = async (first: boolean) => {
      const { holdings, metadata } = await getHoldings(tableName)
      // const currentTime = new Date().getTime()
      if (first) {
         setData(holdings)
      }
      if (metadata && metadata.latestFetch) {
         updateDatabase(holdings, tableName, metadata.delay)
         // const timeSinceLastUpdate = Math.abs(
         //    metadata.latestFetch - currentTime,
         // )
         // if (timeSinceLastUpdate > metadata.delay) {
         //    updateDatabase(holdings, tableName, metadata.delay)
         // } else {
         //    setData(holdings)
         // }
      }
   }

   useEffect(() => {
      fetchHoldings(true)
      const intervalId = setInterval(fetchHoldings, 10000)
      return () => {
         clearInterval(intervalId)
      }
   }, [tableName])

   const handleClick = (filter: string) => {
      if (sort.key === filter) {
         setSort({ key: filter, invert: !sort.invert })
      } else {
         setSort({ key: filter, invert: false })
      }
   }

   const numberWithValues = ['latestPrice', 'changePercent', 'latestUpdate']
   const numberAttributes = ['targetWeight']

   const sortedItems = data
      .sort(function (a: any, b: any) {
         if (numberWithValues.includes(sort.key)) {
            const bValue: any = b[sort.key] ? b[sort.key].value : 0
            const aValue: any = a[sort.key] ? a[sort.key].value : 0
            return sort.invert ? aValue - bValue : bValue - aValue
         } else if (numberAttributes.includes(sort.key)) {
            const bValue: any = b[sort.key] ? b[sort.key] : 0
            const aValue: any = a[sort.key] ? a[sort.key] : 0
            return sort.invert ? aValue - bValue : bValue - aValue
         } else {
            const aValue: any = b[sort.key].toLowerCase()
            const bValue: any = a[sort.key].toLowerCase()
            const aName: any = b['name'].toLowerCase()
            const bName: any = a['name'].toLowerCase()

            if (aValue === bValue) {
               return aName > bName ? -1 : 1
            }
            if (aValue > bValue) {
               return sort.invert ? 1 : -1
            }
            if (bValue > aValue) {
               return sort.invert ? -1 : 1
            }
         }
      })
      .filter((item: any) => item.name)

   const marketStatus = isMarketOpen(new Date())

   return (
      <div className={[styles.container, marketStatus].join(' ')}>
         <table>
            <thead>
               <tr>
                  <td className={sort.key === 'name' ? styles.active : ''}>
                     <button
                        onClick={() => {
                           handleClick('name')
                        }}
                     >
                        {t('holdings')}
                     </button>
                  </td>
                  <td className={sort.key === 'ticker' ? styles.active : ''}>
                     <button
                        onClick={() => {
                           handleClick('ticker')
                        }}
                     >
                        {t('ticker')}
                     </button>
                  </td>
                  <td
                     className={
                        sort.key === 'changePercent' ? styles.active : ''
                     }
                  >
                     <button
                        onClick={() => {
                           handleClick('changePercent')
                        }}
                     >
                        {t('change')}
                     </button>
                  </td>
                  <td
                     className={sort.key === 'latestPrice' ? styles.active : ''}
                  >
                     <button
                        onClick={() => {
                           handleClick('latestPrice')
                        }}
                     >
                        {t('lastPrice')}
                     </button>
                  </td>
                  <td
                     className={
                        sort.key === 'latestUpdate' ? styles.active : ''
                     }
                  >
                     <button
                        onClick={() => {
                           handleClick('latestUpdate')
                        }}
                     >
                        {t('updated')}
                     </button>
                  </td>
                  <td className={sort.key === 'currency' ? styles.active : ''}>
                     <button
                        onClick={() => {
                           handleClick('currency')
                        }}
                     >
                        {t('currency')}
                     </button>
                  </td>
                  <td className={sort.key === 'exchange' ? styles.active : ''}>
                     <button
                        onClick={() => {
                           handleClick('exchange')
                        }}
                     >
                        {t('exchange')}
                     </button>
                  </td>
                  <td className={sort.key === 'category' ? styles.active : ''}>
                     <button
                        onClick={() => {
                           handleClick('category')
                        }}
                     >
                        {t('category')}
                     </button>
                  </td>
                  <td
                     className={
                        sort.key === 'targetWeight' ? styles.active : ''
                     }
                  >
                     <button
                        onClick={() => {
                           handleClick('targetWeight')
                        }}
                     >
                        {t('targetWeight')}
                     </button>
                  </td>
               </tr>
            </thead>
            <tbody>
               {sortedItems &&
                  sortedItems.length > 0 &&
                  sortedItems
                     .map((row: any) => {
                        return {
                           ...row,
                           lastTradeTime:
                              row.exchange === 'us' ? row.lastTradeTime : null,
                           latestUpdate:
                              row.exchange === 'us' ? row.latestUpdate : null,
                           latestPrice:
                              row.exchange === 'us' ? row.latestPrice : null,
                           changePercent:
                              row.exchange === 'us' ? row.changePercent : null,
                        }
                     })
                     .map((row: any) => {
                        let date =
                           row.latestUpdate && row.latestUpdate.value
                              ? new Date(row.latestUpdate.value)
                              : null
                        if (!date) {
                           date =
                              row.lastTradeTime && row.lastTradeTime.value
                                 ? new Date(row.lastTradeTime.value)
                                 : null
                        }
                        // const delay = `${Math.floor(
                        //    Math.random() * (4 - 0 + 1) + 0,
                        // )}s`

                        return (
                           <tr
                              key={`${row.key}-${
                                 row.latestUpdate && row.latestUpdate.value
                                    ? row.latestUpdate.value
                                    : ''
                              }`}
                              className={row.light ? styles.unique : ''}
                           >
                              <td
                                 className={[
                                    styles.rowName,
                                    row.companyLink ? styles.gotLink : '',
                                 ].join(' ')}
                              >
                                 {row.companyLink ? (
                                    <a
                                       href={row.companyLink}
                                       rel="noreferrer"
                                       target="_blank"
                                    >
                                       <span>{row.name}</span>
                                    </a>
                                 ) : (
                                    <span>{row.name}</span>
                                 )}
                              </td>
                              <td
                                 className={[
                                    row.link ? styles.gotLink : '',
                                 ].join(' ')}
                              >
                                 {row.link ? (
                                    <a
                                       href={row.link}
                                       rel="noreferrer"
                                       target="_blank"
                                    >
                                       <span>{row.ticker}</span>
                                    </a>
                                 ) : (
                                    <span>{row.ticker}</span>
                                 )}
                              </td>
                              <td
                                 className={[
                                    row.updated &&
                                    row.latestPrice &&
                                    row.latestPrice.value &&
                                    row.latestPrice.oldValue
                                       ? row.latestPrice.value ===
                                         row.latestPrice.oldValue
                                          ? styles.testTrend
                                          : row.latestPrice.value >
                                            row.latestPrice.oldValue
                                          ? styles.positiveTrend
                                          : styles.negativeTrend
                                       : '',
                                    row &&
                                    row.changePercent &&
                                    row.light !== true
                                       ? row.changePercent.value === 0
                                          ? ''
                                          : row.changePercent.value > 0
                                          ? styles.positive
                                          : styles.negative
                                       : '',
                                 ].join(' ')}
                                 style={
                                    {
                                       // animationDelay: delay,
                                    }
                                 }
                              >
                                 {row.changePercent
                                    ? `${
                                         row.changePercent.value > 0 ? '+' : ''
                                      }${row.changePercent.value.toFixed(2)}%`
                                    : '-'}
                              </td>
                              <td
                                 className={[
                                    row.updated &&
                                    row.latestPrice &&
                                    row.latestPrice.value &&
                                    row.latestPrice.oldValue
                                       ? row.latestPrice.value ===
                                         row.latestPrice.oldValue
                                          ? styles.testTrend
                                          : row.latestPrice.value >
                                            row.latestPrice.oldValue
                                          ? styles.positiveTrend
                                          : styles.negativeTrend
                                       : '',
                                 ].join(' ')}
                                 style={
                                    {
                                       // animationDelay: delay,
                                    }
                                 }
                              >
                                 {row.latestPrice
                                    ? parseFloat(row.latestPrice.value).toFixed(
                                         2,
                                      )
                                    : '-'}
                              </td>
                              <td className={styles.date}>
                                 {date ? (
                                    <Fragment>
                                       {!date && '-'}
                                       {date && (
                                          <span>
                                             {`${date
                                                .getFullYear()
                                                .toString()
                                                .split('')
                                                .splice(2, 2)
                                                .join('')}-${(
                                                date.getMonth() + 1
                                             )
                                                .toString()
                                                .padStart(2, '0')}-${date
                                                .getDate()
                                                .toString()
                                                .padStart(2, '0')}`}
                                          </span>
                                       )}
                                       {date && (
                                          <span>
                                             {`${date
                                                .getHours()
                                                .toString()
                                                .padStart(2, '0')}:${date
                                                .getMinutes()
                                                .toString()
                                                .padStart(2, '0')}:${date
                                                .getSeconds()
                                                .toString()
                                                .padStart(2, '0')}`}
                                          </span>
                                       )}
                                    </Fragment>
                                 ) : (
                                    '-'
                                 )}
                              </td>
                              <td>{row.currency ? row.currency : 'N/A'}</td>
                              <td>{row.exchange ? row.exchange : 'N/A'}</td>
                              <td>{row.category ? row.category : 'N/A'}</td>
                              <td>
                                 {row.targetWeight
                                    ? `${row.targetWeight}%`
                                    : 'N/A'}
                              </td>
                           </tr>
                        )
                     })}
            </tbody>
         </table>
      </div>
   )
}

export default HoldingTable
