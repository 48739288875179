import React from 'react'
import { PagePostTemplateProps } from './types'
import { Flex, Box, Row, Column, Container } from '@components/UI/Grid/Grid'

import styles from './PagePostTemplate.module.scss'
import DynamicComponent from '@components/DynamicComponent/DynamicComponent'
import Label from '@components/UI/Label/Label'

import {
   FacebookShareButton,
   TwitterShareButton,
   LinkedinShareButton,
} from 'react-share'
import { useRouter } from 'next/router'
import LinkComponent from '@components/UI/LinkComponent/LinkComponent'
import Image from '@components/UI/Image/Image'
import NextImage from 'next/image'
import SEOPage from '@components/Head/SEOPage/SEOPage'
import classnames from 'classnames'
import Markdown from '@components/UI/Markdown/Markdown'
import Head from 'next/head'
import moment from 'moment'
import Video from '@components/Video/Video'

const PagePostTemplate: React.FC<PagePostTemplateProps> = ({
   blok,
   story,
   funds,
   periodicItems,
   stories,
}) => {
   let { title, text, seo_data } = blok || {}
   const {
      image,
      video,
      label,
      note,
      link,
      body,
      footer,

      schemas,
      component,
      description,
   } = blok || {}
   const currentYear = new Date().getFullYear()
   seo_data = seo_data.map((data: any) => ({
      ...data,
      seo_title: data.seo_title.replaceAll('%currentyear%', currentYear),
      seo_description: data.seo_description.replaceAll(
         '%currentyear%',
         currentYear,
      ),
   }))
   title = title && title.replaceAll('%currentyear%', currentYear)
   text = text && text.replaceAll('%currentyear%', currentYear)

   const { full_slug, first_published_at = '' } = story || {}
   const { currentNavData, documents } = stories || {}
   // Get root path
   const { locale, asPath } = useRouter()
   let rootPath = asPath.substring(0, asPath.lastIndexOf('/'))
   rootPath = rootPath.substring(0, rootPath.lastIndexOf('/'))

   // Get absolute path for sharing
   let shareUrl = ''
   if (typeof window != 'undefined' && window.location) {
      shareUrl = window.location.href
   }

   const navItems =
      body && body.length > 0
         ? body.filter((blok: any) => blok.label).map((blok: any) => blok.label)
         : []
   const hasImage = image && image?.filename
   const hasVideo = video && video.length > 0 && video[0].youtube_id
   const hasLink = link && link.length > 0 && link[0] ? true : false

   const { title: linkTitle = '' } = hasLink ? link[0] : {}
   const { cached_url = '' } = hasLink ? link[0].link : {}

   return (
      <React.Fragment>
         {schemas && schemas.length > 0 && (
            <Head>
               {schemas.map((schema: any) => (
                  <script
                     key={schema._uid}
                     type="application/ld+json"
                     dangerouslySetInnerHTML={{ __html: schema.schema }}
                  />
               ))}
            </Head>
         )}
         <SEOPage
            storyblokData={seo_data}
            customData={{
               title: title || story?.name || '',
               image: image?.filename || '',
               description: text || '',
            }}
         />
         <Box className={classnames(styles.hero)} display="flex">
            <Container
               className={styles.content}
               paddingTop={[10, null, null, 10]}
               paddingBottom={[10, null, null, 10]}
            >
               <Row alignItems="center" flexDirection="column">
                  <Column
                     width={[1, null, null, 1]}
                     maxWidth={['1000px', null, null, null, null, '1400px']}
                  >
                     {hasVideo
                        ? video.map((blok: any) => (
                             <Box
                                key={blok._uid}
                                className={styles.video}
                                marginBottom={[5, null, null, 10]}
                             >
                                <DynamicComponent key={blok._uid} blok={blok} />
                             </Box>
                          ))
                        : hasImage && (
                             <Box
                                className={styles.image}
                                marginBottom={[5, null, null, 10]}
                             >
                                <Image src={image.filename} />
                             </Box>
                          )}
                  </Column>
                  <Column
                     width={[1, null, null, 1]}
                     maxWidth={['924px', null, null, null, null, '1024px']}
                  >
                     <Flex
                        flexDirection="column"
                        alignItems="flex-start"
                        justifyContent="center"
                     >
                        {label && (
                           <Box marginBottom={[3, null, null, 4]}>
                              <Label title={label} />
                           </Box>
                        )}
                        {title && (
                           <Flex as="h1" marginBottom={[8, null, null, 6]}>
                              <Box>{title}</Box>
                           </Flex>
                        )}
                        {text && (
                           <Box className={styles.text}>
                              <Markdown>{text}</Markdown>
                           </Box>
                        )}
                        {!text && description && (
                           <Box className={styles.text}>
                              <Markdown>{description}</Markdown>
                           </Box>
                        )}
                        {first_published_at && (
                           <Box
                              className={styles.metaData}
                              marginTop={[8, null, null, 10]}
                           >
                              <span className={styles.date}>
                                 {moment(first_published_at)
                                    .locale(locale || 'en')
                                    .format('MMMM DD, YYYY')}
                              </span>
                              {note && (
                                 <React.Fragment>
                                    <Box as="span" paddingX={[2]}>
                                       ·
                                    </Box>
                                    <span>{note}</span>
                                 </React.Fragment>
                              )}
                           </Box>
                        )}
                        {hasLink && (
                           <Box marginTop={[6]}>
                              <LinkComponent
                                 href={cached_url}
                                 className="button purple denese"
                              >
                                 {linkTitle}
                              </LinkComponent>
                           </Box>
                        )}
                     </Flex>
                  </Column>
               </Row>
            </Container>
         </Box>

         <Flex flexDirection="column" width={1} className={styles.body}>
            {body &&
               body?.length > 0 &&
               body.map((blok: any) => {
                  return (
                     <DynamicComponent
                        blok={blok}
                        key={blok._uid}
                        currentNavData={currentNavData}
                        documents={documents}
                        periodicItems={
                           blok.component === 'periodic_system'
                              ? periodicItems
                              : null
                        }
                        isSeoPage={true}
                        isNews={component === 'page_news'}
                        funds={
                           blok.component === 'fund_list' ||
                           blok.component === 'investors'
                              ? funds
                              : null
                        }
                     />
                  )
               })}
            <Box
               as="section"
               className={styles.footer}
               marginTop={[10, null, null, 15]}
               paddingBottom={[10, null, null, 15]}
            >
               <Container>
                  <Box
                     className={styles.border}
                     paddingTop={[10, null, null, 15]}
                  />
                  <Flex justifyContent="space-between" alignItems="center">
                     <LinkComponent href={rootPath} title="Back">
                        <Flex alignItems="center" className={styles.back}>
                           <NextImage
                              src="/icons/arrow.svg"
                              alt="arrow"
                              width="36"
                              height="27"
                           />
                           <Box as="span" marginLeft={[2]}>
                              Back
                           </Box>
                        </Flex>
                     </LinkComponent>
                     <Flex className={styles.share} alignItems="center">
                        <FacebookShareButton
                           url={shareUrl}
                           className={styles.shareButton}
                           title="Share this article on Instagram"
                        >
                           <NextImage
                              src="/icons/facebook-purple.svg"
                              width="26"
                              height="26"
                              alt="Facebook"
                           />
                        </FacebookShareButton>

                        <TwitterShareButton
                           url={shareUrl}
                           className={styles.shareButton}
                           title="Share this article on X"
                        >
                           <NextImage
                              src="/icons/x-purple.svg"
                              width="26"
                              height="26"
                              alt="X"
                           />
                        </TwitterShareButton>
                        <LinkedinShareButton
                           url={shareUrl}
                           className={styles.shareButton}
                           title="Share this article on LinkedIn"
                        >
                           <NextImage
                              src="/icons/linkedin-purple.svg"
                              width="26"
                              height="26"
                              alt="Linkedin"
                           />
                        </LinkedinShareButton>
                     </Flex>
                  </Flex>
               </Container>
            </Box>
            {footer &&
               footer?.length > 0 &&
               footer.map((blok: any) => {
                  return <DynamicComponent blok={blok} key={blok._uid} />
               })}
         </Flex>
      </React.Fragment>
   )
}

export default PagePostTemplate
