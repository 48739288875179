import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { PagePostLandingProps } from './types'
import { Container, Flex, Box, Row, Column } from '@components/UI/Grid/Grid'
import styles from './PagePostLanding.module.scss'
import DynamicComponent from '@components/DynamicComponent/DynamicComponent'
import { HeroStoryblok } from 'types/storyblok-components'
import classnames from 'classnames'
import PostGrid from '@components/PostGrid/PostGrid'
import SEOPage from '@components/Head/SEOPage/SEOPage'
import Head from 'next/head'
import useTranslation from 'next-translate/useTranslation'
import LinkComponent from '@components/UI/LinkComponent/LinkComponent'
import StoryblokClient from 'storyblok-js-client'
import { config } from 'config'
import { postReducer } from 'pages/[[...slug]]'
import { useRouter } from 'next/router'
import debounce from 'lodash.debounce'

const getPostCategory = (cat: any, nextTranslate?: any) => {
   const categoryNames: any = {
      resources: nextTranslate('resources'),
      articles: nextTranslate('articles'),
      podcast: nextTranslate('podcast'),
      videos: nextTranslate('videos'),
      publications: nextTranslate('publications'),
      'monthly-letters': nextTranslate('monthlyLetters'),
      'portfolio-updates': nextTranslate('portfolioUpdates'),
   }
   const categoryName = cat && categoryNames[cat.slug]

   return {
      name: categoryName || '',
      value: cat.slug || '',
      full_slug: cat.full_slug,
   }
}

const PagePostLanding: React.FC<PagePostLandingProps> = ({
   blok,
   story,
   stories,
}) => {
   const { t } = useTranslation('common')
   const { locale } = useRouter()
   const { header, footer, seo_data, schemas } = blok || {}
   const { total, slug } = stories || {}
   const [posts, setPosts] = useState(stories.posts)
   const [notFound, setNotFound] = useState(false)
   const [searchActive, setSearchActive] = useState(false)
   const [loading, setLoading] = useState(false)

   useEffect(() => {
      setPosts(stories.posts)
      const search: any = document.getElementById('article-search')
      if (search) {
         search.value = ''
      }
   }, [stories.slug, locale])

   const sbParams = {
      version: 'published',
      cv: Date.now(),
      language: locale,
      resolve_relations:
         config?.storyblok?.resolveRelations &&
         config.storyblok.resolveRelations?.length > 0
            ? config.storyblok.resolveRelations.join(',')
            : [],
   }

   const debouncedSearch = useMemo(() => {
      return debounce(async (q: string) => {
         setLoading(true)
         const Storyblok = new StoryblokClient({
            accessToken: process.env.NEXT_PUBLIC_STORYBLOK_TOKEN,
            cache: {
               clear: 'auto',
               type: 'memory',
            },
         })
         let sbString = `cdn/stories/?starts_with=${slug}&sort_by=first_published_at:desc&filter_query[component][in]=page_article,page_podcast,page_publication,page_video,page_news&search_term=${encodeURIComponent(
            q,
         )}`
         if (slug === 'research-centre/resources') {
            sbString = `cdn/stories/?starts_with=${slug}&sort_by=first_published_at:desc&filter_query[component][in]=page_article,page_podcast,page_publication,page_video,page_news,page_seo&search_term=${encodeURIComponent(
               q,
            )}`
         }
         const { data: new_posts } = await Storyblok.get(sbString, {
            ...sbParams,
            per_page: 100,
         })
         setPosts(
            new_posts?.stories && new_posts.stories.length > 0
               ? new_posts.stories.map(postReducer)
               : [],
         )
         setLoading(false)
         if (new_posts?.stories && new_posts.stories.length > 0) {
            setNotFound(false)
         } else {
            setNotFound(true)
         }
      }, 400)
   }, [slug, stories])

   const onSearchValueChange = useCallback(
      (e: any) => {
         const { value } = e.target
         if (value) {
            setSearchActive(true)
            debouncedSearch(value)
         } else {
            setNotFound(false)
            setSearchActive(false)
            setPosts(stories.posts)
         }
      },
      [debouncedSearch],
   )

   let categories: any = [
      {
         name: t('all'),
         value: 'all',
         full_slug: '/research-centre',
      },
   ]
   const categoriesOrder = [
      'all',
      'articles',
      'podcast',
      'monthly-letters',
      'videos',
      'publications',
      'portfolio-updates',
      'resources',
   ]

   const handleLoadMore = async () => {
      const Storyblok = new StoryblokClient({
         accessToken: process.env.NEXT_PUBLIC_STORYBLOK_TOKEN,
         cache: {
            clear: 'auto',
            type: 'memory',
         },
      })
      let sbString = `cdn/stories/?starts_with=${slug}&sort_by=first_published_at:desc&filter_query[component][in]=page_article,page_podcast,page_publication,page_video,page_news`
      if (slug === 'research-centre/resources') {
         sbString = `cdn/stories/?starts_with=${slug}&sort_by=first_published_at:desc&filter_query[component][in]=page_article,page_podcast,page_publication,page_video,page_news,page_seo`
      }
      const { data: new_posts } = await Storyblok.get(sbString, {
         ...sbParams,
         per_page: 100,
      })
      setPosts(
         new_posts?.stories && new_posts.stories.length > 0
            ? new_posts.stories.map(postReducer)
            : posts,
      )
   }

   stories?.categories?.forEach((cat: any) => {
      const category = getPostCategory(cat, t) || {
         name: '',
         value: '',
      }

      if (
         category &&
         !categories.find((c: any) => c.value === category.value)
      ) {
         categories.push(category)
      }
   })
   categories = categoriesOrder
      .map((cat: string) => {
         const find = categories.find((inner: any) => inner.value === cat)
         return find
      })
      .filter((item: any) => item)

   const catSlug =
      slug === 'research-centre/'
         ? 'all'
         : slug.replace('research-centre/', '').replace('/', '')

   let activeCategory = categories.find((cat: any) => cat.value === catSlug)
   if (!activeCategory) {
      activeCategory = categories[0]
   }
   // Sort articles by published date
   const postsSortedByDate =
      posts && posts.length > 0
         ? posts.sort(function (a: any, b: any) {
              const aDate: Date = a?.first_published_at
                 ? new Date(a.first_published_at)
                 : new Date()
              const bDate: Date = b?.first_published_at
                 ? new Date(b?.first_published_at)
                 : new Date()
              return bDate.getTime() - aDate.getTime()
           })
         : []

   return (
      <React.Fragment>
         {schemas && schemas.length > 0 && (
            <Head>
               {schemas.map((schema: any) => (
                  <script
                     key={schema._uid}
                     type="application/ld+json"
                     dangerouslySetInnerHTML={{ __html: schema.schema }}
                  />
               ))}
            </Head>
         )}
         <SEOPage
            storyblokData={seo_data}
            customData={{
               title: header?.[0].title || story?.name || '',
               description: header?.[0].subtitle || '',
            }}
         />
         {header &&
            header?.length > 0 &&
            header.map((blok: HeroStoryblok) => {
               return (
                  <DynamicComponent
                     blok={{ ...blok, smallMobile: true }}
                     key={blok._uid}
                  />
               )
            })}
         <Box as="section" className={styles.wrapper}>
            <Container
               bg="var(--lighter-purple)"
               paddingY={[10, null, null, 10]}
               className={styles.catContainer}
               paddingX={[0, null, null, 'var(--container-side-padding)']}
            >
               <Row justifyContent="center">
                  <Flex alignItems="center" className={styles.tabsOuter}>
                     <Flex className={styles.tabs}>
                        {categories.map((category: any) => {
                           return (
                              <LinkComponent
                                 key={category.value}
                                 href={
                                    category.full_slug
                                       ? category.full_slug
                                       : '/research-centre'
                                 }
                              >
                                 <Flex
                                    justifyContent="center"
                                    alignItems="center"
                                    key={category.value}
                                    paddingX={[
                                       '12px',
                                       null,
                                       null,
                                       '8px',
                                       '12px',
                                    ]}
                                    className={classnames(
                                       styles.tab,
                                       activeCategory.value === category.value
                                          ? styles.active
                                          : '',
                                    )}
                                 >
                                    {category.name}
                                 </Flex>
                              </LinkComponent>
                           )
                        })}
                     </Flex>
                     <Flex className={styles.searchOuter}>
                        <Flex className={styles.search}>
                           <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 d="M6.5 13C4.68333 13 3.14583 12.3708 1.8875 11.1125C0.629167 9.85417 0 8.31667 0 6.5C0 4.68333 0.629167 3.14583 1.8875 1.8875C3.14583 0.629167 4.68333 0 6.5 0C8.31667 0 9.85417 0.629167 11.1125 1.8875C12.3708 3.14583 13 4.68333 13 6.5C13 7.23333 12.8833 7.925 12.65 8.575C12.4167 9.225 12.1 9.8 11.7 10.3L17.3 15.9C17.4833 16.0833 17.575 16.3167 17.575 16.6C17.575 16.8833 17.4833 17.1167 17.3 17.3C17.1167 17.4833 16.8833 17.575 16.6 17.575C16.3167 17.575 16.0833 17.4833 15.9 17.3L10.3 11.7C9.8 12.1 9.225 12.4167 8.575 12.65C7.925 12.8833 7.23333 13 6.5 13ZM6.5 11C7.75 11 8.8125 10.5625 9.6875 9.6875C10.5625 8.8125 11 7.75 11 6.5C11 5.25 10.5625 4.1875 9.6875 3.3125C8.8125 2.4375 7.75 2 6.5 2C5.25 2 4.1875 2.4375 3.3125 3.3125C2.4375 4.1875 2 5.25 2 6.5C2 7.75 2.4375 8.8125 3.3125 9.6875C4.1875 10.5625 5.25 11 6.5 11Z"
                                 fill="#808080"
                              />
                           </svg>
                           <input
                              onChange={onSearchValueChange}
                              type="text"
                              id="article-search"
                              placeholder={t('searchArticles')}
                           ></input>
                        </Flex>
                     </Flex>
                  </Flex>
               </Row>
            </Container>
            <Container
               className={loading ? styles.loading : ''}
               paddingBottom={[10, null, null, 15]}
            >
               <Box marginTop={[5, null, null, 15]}>
                  {notFound && (
                     <div className={styles.notFound}>
                        <h3>{t('searchNotFound')}</h3>
                        <p>{t('searchNotFoundText')}</p>
                     </div>
                  )}
                  <PostGrid preloadFirst posts={postsSortedByDate} />
               </Box>
               {posts.length <= total && !searchActive && (
                  <div className={styles.loadMore}>
                     <button className={styles.button} onClick={handleLoadMore}>
                        {t('loadMore')}
                     </button>
                  </div>
               )}
            </Container>
            {footer &&
               footer?.length > 0 &&
               footer.map((blok: any) => (
                  <DynamicComponent
                     key={blok._uid}
                     blok={blok}
                     className={styles.buttonFilled}
                  />
               ))}
         </Box>
      </React.Fragment>
   )
}

export default PagePostLanding
