import React, { useState } from 'react'
import { TextAndMediaProps } from './types'
import styles from './ClickSend.module.scss'
import { Flex, Container, Box, Row, Column } from '@components/UI/Grid/Grid'
import Label from '@components/UI/Label/Label'
import Markdown from '@components/UI/Markdown/Markdown'
import { getThemeColor, getTitleMarkup, stringify } from '@lib/helpers'
import DynamicComponent from '@components/DynamicComponent/DynamicComponent'
import classnames from 'classnames'
import { CardContent } from '@components/PostGrid/PostGridCard/PostGridCard'
import moment from 'moment'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'

const ClickSendForm = dynamic(() => import('./ClickSendForm'), {
   loading: () => <div />,
})

const ClickSend: React.FC<TextAndMediaProps> = ({ blok }) => {
   const {
      label,
      title,
      description,
      background,
      videos,
      title_markup,
      signup_title,
      signup_title_markup,
      signup_text,
      list_title,
      signup_success,
      list_title_markup,
   } = blok
   const [selectedVideo, setSelectedVideo] = useState(videos ? videos[0] : null)
   const { locale } = useRouter()
   return (
      <Flex
         as="section"
         width={1}
         className={styles.wrapper}
         bg={getThemeColor(background).background}
         color={getThemeColor(background).color}
      >
         <div id={label ? stringify(label) : ''} className="anchor" />
         <Container>
            <Box
               paddingTop={[10, null, null, 20]}
               paddingBottom={[10, null, 20]}
            >
               <Row
                  marginBottom={[10, null, null, 16]}
                  justifyContent="space-between"
               >
                  <Column width={[1]}>
                     <Flex
                        alignItems={['flex-start']}
                        width={[1, null, null, 6 / 12]}
                        flexDirection="column"
                     >
                        {label && (
                           <Box marginBottom={[4, null, null, 6]}>
                              <Label title={label} />
                           </Box>
                        )}
                        <Flex flexDirection="column">
                           {title && (
                              <Box
                                 as={getTitleMarkup(title_markup)}
                                 className={classnames(styles.title)}
                                 marginBottom={[5, null, null, 6]}
                              >
                                 {title}
                              </Box>
                           )}
                           {description && (
                              <Box>
                                 <Markdown className={styles.text}>
                                    {description}
                                 </Markdown>
                              </Box>
                           )}
                        </Flex>
                     </Flex>
                  </Column>
               </Row>
               <Row>
                  <Column width={[1, null, null, 8 / 12]}>
                     {selectedVideo &&
                        selectedVideo.content &&
                        selectedVideo.content.video && (
                           <Flex
                              key={blok._uid}
                              flexDirection="column"
                              style={{ position: 'relative' }}
                           >
                              <div className="anchor" id="clicksend-video" />
                              <div className={styles.video}>
                                 <DynamicComponent
                                    key={blok._uid}
                                    blok={selectedVideo.content.video[0]}
                                 />
                              </div>
                           </Flex>
                        )}
                  </Column>
                  <Column
                     order={[3, null, null, 2]}
                     width={[1, null, null, 4 / 12]}
                     marginBottom={[12, null, null, 0]}
                  >
                     <div className={styles.formSection}>
                        {signup_title && (
                           <Box
                              as={getTitleMarkup(signup_title_markup)}
                              className={classnames(styles.signupTitle)}
                              marginBottom={[5, null, null, 6]}
                           >
                              {signup_title}
                           </Box>
                        )}
                        {signup_text && (
                           <Box>
                              <Markdown className={styles.signupText}>
                                 {signup_text}
                              </Markdown>
                           </Box>
                        )}
                        <ClickSendForm successMessage={signup_success} />
                     </div>
                  </Column>
                  <Column
                     width={[1, null, null, 8 / 12]}
                     order={[2, null, null, 3]}
                     marginTop={[4]}
                  >
                     {selectedVideo && (
                        <Flex
                           paddingBottom={[0, null, null, 16]}
                           marginBottom={[6, null, null, 0]}
                           flexDirection="column"
                           maxWidth={[1024]}
                        >
                           <Box>
                              {selectedVideo.first_published_at && (
                                 <span className={styles.videoTag}>
                                    {moment(selectedVideo.first_published_at)
                                       .locale(locale || 'en')
                                       .format('MMMM DD, YYYY')}
                                 </span>
                              )}
                           </Box>
                           <Box
                              className={styles.videoTitle}
                              marginTop={[4]}
                              as="h4"
                           >
                              {selectedVideo.content.title}
                           </Box>
                           {selectedVideo.content.description && (
                              <Box
                                 as="p"
                                 className={styles.videoDesc}
                                 marginTop={[2]}
                              >
                                 {selectedVideo.content.description}
                              </Box>
                           )}
                        </Flex>
                     )}
                  </Column>
               </Row>
               <Row>
                  <Column>
                     {list_title && (
                        <Box
                           as={getTitleMarkup(list_title_markup)}
                           className={classnames(styles.listTitle)}
                           marginBottom={[5, null, null, 6]}
                        >
                           {list_title}
                        </Box>
                     )}
                  </Column>
               </Row>
               {videos &&
                  videos.length > 0 &&
                  selectedVideo &&
                  selectedVideo.id && (
                     <div className={styles.list}>
                        {videos?.map((video: any, index: number) =>
                           video ? (
                              <div
                                 onClick={() => {
                                    setSelectedVideo(video)
                                    const el =
                                       document.getElementById(
                                          'clicksend-video',
                                       )
                                    if (el) {
                                       el.scrollIntoView({ behavior: 'smooth' })
                                    }
                                 }}
                                 key={video.id}
                              >
                                 <CardContent
                                    noHover={video.id === selectedVideo.id}
                                    hideTag
                                    hideDesc
                                    background={background}
                                    item={video}
                                 />
                              </div>
                           ) : null,
                        )}
                     </div>
                  )}
            </Box>
         </Container>
      </Flex>
   )
}

export default ClickSend
