import React from 'react'
import { TimelineProps } from './types'
import styles from './Timeline.module.scss'
import { Flex, Container, Row, Column, Box } from '@components/UI/Grid/Grid'
import Label from '@components/UI/Label/Label'
import { getThemeColor, getTitleMarkup, stringify } from '@lib/helpers'
import { TimelineItemStoryblok } from 'types/storyblok-components'
import Markdown from '@components/UI/Markdown/Markdown'
import SbEditable from 'storyblok-react'
import GraphicWave from '@components/UI/GraphicWave/GraphicWave'
import classnames from 'classnames'

const Timeline: React.FC<TimelineProps> = ({ blok }) => {
   const {
      label,
      title,
      text,
      text_column_2,
      timeline_items,
      timeline_title,
      background,
      below_title,
      show_graphic_element,
      isLast,
      title_markup,
   } = blok

   return (
      <Flex
         as="section"
         className={styles.wrapper}
         bg={getThemeColor(background).background}
         color={getThemeColor(background).color}
      >
         <div id={label ? stringify(label) : ''} className="anchor" />
         <Container>
            <Flex
               flexDirection="column"
               alignItems={['flex-start', null, null, 'flex-start']}
               className={styles.content}
               width={1}
               paddingTop={[10, null, null, 20]}
               paddingBottom={[10, null, null, 20]}
            >
               <Box marginBottom={[4, null, null, 6]}>
                  {label && <Label title={label} />}
               </Box>
               <Box
                  as={getTitleMarkup(title_markup)}
                  width={[1, null, null, 3 / 5]}
                  marginBottom={[5, null, null, 5]}
                  className={styles.title}
               >
                  {title}
               </Box>
               <Box
                  marginBottom={[0, null, null, 6]}
                  paddingBottom={[4, null, null, 6]}
                  width={1}
                  className={styles.heading}
               >
                  <Box
                     className={styles.subtitle}
                     as="p"
                     width={[1, null, null, 1 / 2]}
                  >
                     {timeline_title}
                  </Box>
               </Box>
               {timeline_items && timeline_items?.length > 0 && (
                  <Box
                     className={styles.timeline}
                     width={1}
                     marginTop={[6]}
                     style={{
                        gridTemplateColumns: `repeat(${timeline_items.length},1fr)`,
                     }}
                     marginBottom={[5, null, null, 10]}
                  >
                     {timeline_items.map(
                        (blok: TimelineItemStoryblok, index: number) => {
                           return (
                              <SbEditable key={blok._uid} content={blok}>
                                 <Box
                                    width={[1]}
                                    paddingRight={[4, null, null, 0]}
                                    className={[
                                       styles.item,
                                       background === 'grey'
                                          ? styles.black
                                          : '',
                                    ].join(' ')}
                                    marginBottom={[4, null, null, 0]}
                                 >
                                    <Flex
                                       paddingLeft={[
                                          0,
                                          null,
                                          null,
                                          index === 0 ? 0 : 9,
                                       ]}
                                       paddingRight={[0, null, null, 9]}
                                       marginBottom={[5, null, null, 0]}
                                       flexDirection="column"
                                    >
                                       <Box
                                          marginBottom={[5, null, null, 12]}
                                          className={styles.label}
                                       >
                                          {blok.label}
                                       </Box>
                                       <Markdown className={styles.text}>
                                          {blok.text}
                                       </Markdown>
                                    </Flex>
                                 </Box>
                              </SbEditable>
                           )
                        },
                     )}
                  </Box>
               )}

               {below_title && (
                  <Box
                     marginBottom={[5, null, null, 6]}
                     width={[1, null, null, 1 / 2]}
                     className={styles.belowTitle}
                     as="span"
                  >
                     {below_title}
                  </Box>
               )}
               <Box alignSelf="stretch">
                  <Row flexDirection="row">
                     <Column width={[1, null, null, 6 / 12]}>
                        <Box width={1}>
                           {text && (
                              <Markdown
                                 className={classnames(
                                    styles.belowText,
                                    !text_column_2 ? styles.isOneColumn : '',
                                 )}
                              >
                                 {text}
                              </Markdown>
                           )}
                        </Box>
                     </Column>
                     {text_column_2 && (
                        <Column width={[1, null, null, 6 / 12]}>
                           <Markdown>{text_column_2}</Markdown>
                        </Column>
                     )}
                  </Row>
               </Box>
            </Flex>
         </Container>
         {show_graphic_element && (
            <GraphicWave
               className={
                  isLast
                     ? [styles.graphic, styles.last].join(' ')
                     : styles.graphic
               }
            />
         )}
      </Flex>
   )
}

export default Timeline
