import { ref, set } from 'firebase/database'
import { getFirebaseDatabase } from './firebase'

export const updateHoldingsDatabase = async (holdings, tableName, delay) => {
   const symbols = holdings
      .filter((holding) => holding.exchange === 'us')
      .map((holding) => holding.key && holding.key.replace('*', '.'))
   const foundSymbols = []

   // const trends = []
   let response = holdings
   await fetch(
      `https://api-v2.intrinio.com/stock_exchanges/USCOMP/quote?source=iex&tickers=${symbols
         .toString()
         .toUpperCase()}`,
      {
         method: 'get',
         headers: {
            Authorization: 'OmY3YWFmMTU4ODhjOWUwNjYxZDY1NGY3MmVmMGIwMzE2',
         },
      },
   )
      .then(function (e) {
         return e.ok ? e.json() : 'error'
      })
      .then(function (t) {
         if (t === 'error') {
            console.log('FETCH DIDNT WORK')
         } else {
            var r = new Date().getTime()
            const db = getFirebaseDatabase()
            if (t && t.quotes) {
               t.quotes.forEach((stock) => {
                  const { last, last_time, change_percent, security } = stock
                  const prevData = holdings.find(
                     (h) =>
                        h.key.toUpperCase().replace('.', '*') ===
                        security.ticker,
                  )
                  const prevLatestUpdate =
                     prevData &&
                     prevData.latestUpdate &&
                     prevData.latestUpdate.value

                  const last_update = new Date(last_time)

                  if (prevLatestUpdate) {
                     const prevLatestUpdate =
                        prevData &&
                        prevData.latestUpdate &&
                        prevData.latestUpdate.value

                     foundSymbols.push(prevData.key)
                     if (prevLatestUpdate !== last_update.getTime()) {
                        set(
                           ref(
                              db,
                              `${tableName}/data/changePercent/${prevData.key}`,
                           ),
                           {
                              value: change_percent,
                           },
                        )
                        set(
                           ref(
                              db,
                              `${tableName}/data/latestPrice/${prevData.key}`,
                           ),
                           {
                              value: last,
                           },
                        )
                        set(
                           ref(
                              db,
                              `${tableName}/data/latestUpdate/${prevData.key}`,
                           ),
                           {
                              value: last_update.getTime(),
                           },
                        )
                        set(
                           ref(
                              db,
                              `${tableName}/data/lastTradeTime/${prevData.key}`,
                           ),
                           {
                              value: last_update.getTime(),
                           },
                        )
                     }
                  }
               })
               set(ref(db, `/${tableName}/metadata`), {
                  latestFetch: r,
                  delay: delay,
               })
            }
         }

         const missingSymbols = symbols.filter(
            (symbol) => !foundSymbols.includes(symbol),
         )
         if (missingSymbols && missingSymbols.length > 0) {
            const db = getFirebaseDatabase()
            missingSymbols.forEach((symbol) => {
               const prevData = holdings.find((h) => h.key === symbol)
               if (
                  prevData &&
                  prevData.latestUpdate &&
                  prevData.latestUpdate.value !== 0
               ) {
                  set(ref(db, `${tableName}/data/latestUpdate/${symbol}`), {
                     value: 0,
                  })
                  set(ref(db, `${tableName}/data/lastTradeTime/${symbol}`), {
                     value: 0,
                  })
               }
            })
         }

         if (holdings && t) {
            response = holdings.map((item) => {
               const hKey = item.key.toUpperCase().replace('*', '.')

               const stock = t.quotes.find(
                  (item) => item.security.ticker === hKey,
               )

               if (stock) {
                  const latestPrice = stock.last
                  const changePercent = stock.change_percent
                  // const changePercent =
                  //    t && t[hKey] && t[hKey].quote && t[hKey].quote.changePercent
                  const latestUpdate = new Date(stock.last_time).getTime()

                  // const lastTradeTime =  new Date(stock.last_time).getTime()

                  if (latestPrice) {
                     return {
                        ...item,
                        updated: true,
                        latestPrice: {
                           value: latestPrice,
                           oldValue: item.latestPrice && item.latestPrice.value,
                        },
                        changePercent: {
                           value: changePercent,
                           oldValue:
                              item.changePercent && item.changePercent.value,
                        },
                        latestUpdate: {
                           value: latestUpdate,
                        },
                        lastTradeTime: {
                           value: latestUpdate,
                        },
                     }
                  } else {
                     return item
                  }
               } else {
                  return item
               }
            })
         }
      })
   return response
}
